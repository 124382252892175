import React, { Component } from 'react';
import ReactGA from 'react-ga';
import LinkButton from './LinkButton';
import Logo from '../images/logo_esperanzas.png';
import data from '../constants/pages.json';

export default class Layout extends Component {
  componentDidMount() {
    ReactGA.initialize(window.gaId);
    ReactGA.pageview('/');
  }

  render() {
    return (
      <div className="container">
        <div className="container-max-width">
          <header>
            <img src={Logo} alt="Esperanza's Tours" />
          </header>
          <h1>
            How was your tour in Los Cabos?
          </h1>
          <div className="container-links">
            <p>Thank you for visiting Los Cabos and choosing us as your experiences provider.</p>
            <p>We’d be grateful if you would review us on the different platforms we are registered. Doing this will help new visitors every month to plan the perfect trip.</p>
            <p>Our guests often tell us how helpful it is to read past guest's reviews before booking their own visits. And we're always eager to hear what you liked and how we can improve.</p>
            <h2>How would you rate us overall?</h2>
            {
              data.pages.map((button, index) => 
              <LinkButton
                key={index}
                {...button}
              />)
            }
          </div>
        </div>
      </div>
    )
  }
}
