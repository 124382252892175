import React from 'react';
import ReactGA from 'react-ga';
import detectBrowserLanguage from 'detect-browser-language';
const language = detectBrowserLanguage();
let locale = language.includes('en') ? 'en' : 'es';

const LinkButton = ({name, name_es, pageview, background, icon, link, iconSize}) => (
  <div className="link-button">
    <a
      style={{backgroundColor: background}}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <span
        style={{fontSize: iconSize}}
        className={`icon-${icon}`}
      />
      {locale === 'es' ? (name_es ? name_es : name) : name}
    </a>
  </div>
);

export default LinkButton;